import { SxProps, Theme } from '@mui/material';

export const wacoalFooterContainerSX = (
	csrSession: boolean,
	isHomepage: boolean,
	isBtemptd: boolean
): SxProps<Theme> => ({
	alignSelf: 'stretch',
	justifySelf: 'flex-end',
	...(!csrSession && { mt: 'auto' }),
	'> div': {
		backgroundColor: isBtemptd ? 'transparent' : '#252525',
		marginTop: isHomepage ? 0 : '72px',
	},
});
