/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */
import { ContentItemProperties } from '@/data/types/Slot';
const contentItems: ContentItemProperties[] = [
	{ emsName: 'HeaderStoreLogo' },
	{ emsName: 'AccountIcon' },
	{ emsName: 'MiniShopCart' },
	{ emsName: 'SearchIcon' },
	{ emsName: 'MainLogo' },
	{ emsName: 'StoreFlag' },
	{ emsName: 'USStoreFlag' },
	{ emsName: 'CanadaStoreFlag' },
	{ emsName: 'React_wacoal_StoreLogo_Content' },
	{ emsName: 'React_btemptd_StoreLogo_Content' },
	{ emsName: 'ReactHeaderBanner' },
	{ emsName: 'ReactHeaderPopup' },
];
export default contentItems;
