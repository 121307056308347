/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { ContentRecommendation } from '@/components/content/ContentRecommendation';
import { ID } from '@/data/types/Basic';
import { Button } from '@mui/material';
import { FC, useEffect, useState } from 'react';

export const ScrollToTop: FC<{
	id: ID;
	clickAction?: () => void;
}> = ({ id }) => {
	const [visible, setVisible] = useState(false);

	const toggleVisible = () => {
		const scrolled = document.documentElement.scrollTop;
		if (scrolled > 300) {
			setVisible(true);
		} else if (scrolled <= 300) {
			setVisible(false);
		}
	};

	const backToTop = () => {
		if (window) {
			window.scrollTo({ top: 0, behavior: 'smooth' });
		}
	};

	useEffect(() => {
		window.addEventListener('scroll', toggleVisible);
	}, []);

	return visible ? (
		<Button
			onClick={backToTop}
			sx={{
				position: 'fixed',
				bottom: { xs: '30px', lg: '70px' },
				right: { xs: '0', lg: '28px' },
				/* bottom: '85px',
				right: '28px',*/
				padding: 0,
			}}
		>
			<ContentRecommendation
				key="ReactScrollToTop"
				id={id}
				properties={{ emsName: 'ReactScrollToTop' }}
			/>
		</Button>
	) : null;
};
