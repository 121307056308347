import { ContentRecommendation } from '@/components/content/ContentRecommendation';
import { ScrollToTop } from '@/components/content/Footer/ScrollToTop';
import { wacoalFooterContainerSX } from '@/components/content/Footer/styles/wacoalContainer';
import { useFooter } from '@/data/Content/Footer';
import { useStoreName } from '@/data/Content/StoreName';
import { useSettings } from '@/data/Settings';
import { usePageDataFromId } from '@/data/_PageDataFromId';
import { ID } from '@/data/types/Basic';
import { Box } from '@mui/material';
import { FC } from 'react';

export const Footer: FC<{
	id: ID;
	clickAction?: () => void;
}> = ({ id }) => {
	const { settings } = useSettings();
	const { contentItems } = useFooter(id);
	const { isHomepage } = usePageDataFromId();
	const { isBtemptd } = useStoreName();

	return (
		<Box
			component="footer"
			sx={wacoalFooterContainerSX(settings?.csrSession, isHomepage, isBtemptd)}
			{...(settings?.csrSession && { 'data-iframe-height': '' })}
		>
			<Box>
				{contentItems.map((properties) => (
					<ContentRecommendation
						key={properties.emsName}
						id={`${id}-${properties.emsName}`}
						properties={properties}
					/>
				))}
			</Box>
			<ScrollToTop id={id}></ScrollToTop>
		</Box>
	);
};
