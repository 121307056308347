import { useSettings } from '@/data/Settings';
import {
	BTEMPTD_STORE_NAME,
	WACOAL_AMERICA_STORE_NAME,
	WACOAL_CANADA_STORE_NAME,
} from '@/data/constants/store';
import { useMemo } from 'react';

export const useStoreName = () => {
	const { settings } = useSettings();

	const { isWacoal, isWacoalAmerica, isWacoalCanada, isBtemptd } = useMemo(
		() => ({
			isWacoal:
				settings.storeName === WACOAL_AMERICA_STORE_NAME ||
				settings.storeName === WACOAL_CANADA_STORE_NAME,
			isWacoalAmerica: settings.storeName === WACOAL_AMERICA_STORE_NAME,
			isWacoalCanada: settings.storeName === WACOAL_CANADA_STORE_NAME,
			isBtemptd: settings.storeName === BTEMPTD_STORE_NAME,
		}),
		[settings]
	);

	return { isWacoal, isWacoalAmerica, isWacoalCanada, isBtemptd };
};
